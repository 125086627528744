.IconHoverBorder {
    color: black;
    display: flex

}

.iconHoverBox {
    -webkit-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
}

.filter-white {
    filter: invert(99%) sepia(100%) saturate(1%) hue-rotate(125deg) brightness(103%) contrast(99%);
    -webkit-transition : -webkit-filter 200ms linear
    
}