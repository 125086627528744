#whoAreWeBorder { 
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;

    flex-direction: row;
}

#wholeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#whoAreWeContainer {
    min-width: 70%;
    width: 70%;
    min-height: 30%;
    border-radius: 3vh;
    justify-content: right;
    text-align: right;
    padding: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.colorStuffBefore {
    background-color: #FEFEFE;
    color: black
}
.colorStuffAfter {
    background-color: #682c84;
    -webkit-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear;

}

.whoAreWePictureBorder img {
    position: relative;
    background-repeat: 'no-repeat';
    background-position : 'center';
    object-fit: cover;
    width: 40vw;
    height : 30vh;
    border-radius: 30px;
    top: 25vh;
    right: 25vw;

}

#whoAreWeContainer div {
    display: inline-block;
    align-self: end;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.buttonFade {
    animation: fadeIn 1s;
}