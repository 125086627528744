.purpleIn {
    position: fixed;
    border-radius: 100%;
    animation-delay: 500ms;
    animation: purpleMoveIn 1s forwards;
}
.purpleOut {
    position: fixed;
    border-radius: 100%;
    animation-delay: 500ms;
    animation: purpleMoveOut 1s forwards;
}
@keyframes purpleMoveIn {
    0% {transform: scale(2);  }
    100% {transform: scale(0.5);}
}
@keyframes purpleMoveOut {
    0% { transform: scale(0.5); }
    100% { transform: scale(2);}
}

.textInx {
    animation: textFadeInx 500ms ease-in;
    animation-fill-mode: forwards;
}

.textOutx {
    animation: textFadeOutx 500ms ease-out;
    animation-fill-mode: forwards;
}

@keyframes textFadeInx {
    0% {opacity: 0;}
    100% {opacity: 1;}
}
@keyframes textFadeOutx {
    0% {opacity: 1;}
    100% {opacity: 0;}
}