#faq {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    
    background-color: #FDFDFD;
    color: black;
    margin-bottom: 10vmin;
}

#faqTitle {
    font-weight: 700;
    text-align: center;
    font-size: calc(10vmin);;
}

#faqquestions {
    text-align: center;
    margin-right: 30%;
}

.bullet {
    padding: 10px;
    padding-bottom: 4%;
}