
@import url('https://fonts.googleapis.com/css2?family=Honk&family=Lato:wght@400;900&display=swap');

.App {
  text-align: center;
  font-family: lato;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.App-body {
  background-color: #FDFDFD;
  font-family: lato;
  font-size: calc(10px + 2vmin);
  color: #FDFDFD;
  height: 100vh;
  overflow-y: hidden;
}

.App-link {
  color: #51a6be;
}

.footerList {
  margin: 0;
  padding: 0 2vw
}
.footerList dd{
  margin: 0;
  font-weight: 400;
  color: #4A4A4A
}

.svgimage {
  padding: '10px';
  width: '10px'
}

h1 {
  font-size: 12vmin;
}