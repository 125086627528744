.fadeInDownward {
    opacity: 0;
    animation: fadeInDownLog 500ms;
    animation-delay: 300ms;
    animation-fill-mode: forwards;
}

.repeat {
  opacity: 0;
  animation: fadeInDownLog2 500ms;
  animation-delay: 300ms;
  animation-fill-mode: forwards;
}

.restingPosition {
  opacity: 0;
}



@keyframes fadeInDownLog {
    0% {
      transform: translateY(-10%);
      opacity: 0;
    }
    100% {
      transform: translateY(0%);
      opacity: 1;
    }
  }

  
@keyframes fadeInDownLog2 {
  0% {
    transform: translateY(-10%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}