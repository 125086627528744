.LogoBorder {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    font-size: 18vmin;
    font-weight: 600;
    animation: fadeInDownLog 700ms;
}


@keyframes smooth-aappear {
  to{
    right: 60px;
  }
}

@keyframes smooth-ddisappear {
  to {
    right: -50%;
  }
}

  .hamPopOut {
  filter: invert(4%) sepia(16%) saturate(6%) hue-rotate(321deg) brightness(5%) contrast(87%);
  right: -30%;
  top: 4vw;
  transform: translateX(40px);
  animation: smooth-aappear 400ms ease forwards;
  z-index: 7;
  visibility:visible;
  
}

.hamPopOutExit {
  filter: invert(4%) sepia(16%) saturate(6%) hue-rotate(321deg) brightness(5%) contrast(87%);
  top: 4vw;
  right: 0px;
  transform: translateX(40px, 0px);
  animation: smooth-ddisappear 400ms ease forwards;
  z-index: 7;
  visibility:visible;
}

.hamInitialHidden {
  top: 4vw;
  visibility: hidden;
}


.SecondBorder {
    background-color: #FDFDFD;
    color: #000;
    min-height: 105vh;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    font-size: calc(2.5vmin);
    font-weight: 100;
}

.textFadeIn {
    animation-delay: 1000ms;
    animation: fadeIn 2s;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}


.text {
    margin: 0px;
    padding: 0px;
    line-height: 80%;
    text-align: center;
}

@keyframes fadeInDownLog {
  0% {
    transform: translateY(-10%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}