.ourValuesBackgroundChangeEffect {
    -webkit-transition: background-color 750ms linear;
    -ms-transition: background-color 750ms linear;
    transition: background-color 750ms linear;
}

@keyframes vvaluesSlowTextAppear {
    0% {
        display: none;
    }
    50% {
        display: none;
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


@keyframes vvaluesSlowTextDisappear {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
        display: none;
    }
    100% {
        display: none;
    }
}

.valuesSlowTextAppear {

    animation: vvaluesSlowTextAppear 750ms;
}

.valuesSlowDisappear {
    animation: vvaluesSlowTextDisappear forwards 750ms;
}
