.container {
    width: 300px;
    margin: 50px auto;
  }
  
  .content {
    overflow: hidden;
    transition: height 0.5s ease;
    
  }
  
  .show-more {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s ease;
  }
  
  .show-more:hover {
    background-color: #0056b3;
  }

  .upspin {
    transition: transform 0.5s ease;
  }
  
  .upspin.spin {
    transform: rotate(180deg);
  }