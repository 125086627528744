.slick-prev {
  left: 4% !important;
  z-index: 1;

}

.slick-next {
  right: 4% !important;
  z-index: 1;
  color: #000;
}

.slick-prev:before,
.slick-next:before {
  color: #000;
}


.pictureCarImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 3vmin;

}

.pictureCarDiv {
  padding: 2vmin;
  margin: 0px 2vmin;
  max-width: 100%;
  height: 80vmin;
}

.slick-list div {
  padding: 0 !important;
  padding-left: 0;
  padding-right: 0;

}


.slick-slide div {
  outline: none !important;
  padding: 0;
  height: 90vmin;


}

.slick-dots {
  bottom: 1vmin;

}

.slick-dots li button:before {
  margin: 0;
  font-size: 20px;
  line-height: 20px;
  color: 'red'
}
.slick-dots li button:before {
  color: white !important;
  opacity: .75;
}


.slick-dots li.slick-active button:before {
  color:#652C85 !important;
  opacity: 1;
}

