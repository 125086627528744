
@keyframes smooth-appear {
    to{
      left: 0%;
    }
  }

@keyframes smooth-disappear {
    to {
      left: 100%;
    }
    
}
  .popOutNav {
    position: fixed;
    right: 100%;
    left: 70%;
    top: 0%;
    transform: translateX(-70%);
    animation: smooth-appear 400ms ease forwards;
    z-index: 7;
    overflow-y: 'scroll';
    height: 'auto'
  }

  .popOutNavExit {
    position: fixed;
    right: 0%;
    left: 0%;
    top: 0%;
    transform: translateX(0%);
    animation: smooth-disappear 400ms ease forwards;
    z-index: 7;
  }

  .onHover {
    color: #4A4A4A
  }

  .onHover:hover {
    color: rgb(104, 44, 132)
  }
  