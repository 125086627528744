.container {
    width: 200px;
    height: 200px;
    position: relative;
    overflow: hidden;
}

.diagonal {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom right, #ff0000, #0000ff);
    animation: diagonalTransition 2s ease-in-out infinite;
}

@keyframes diagonalTransition {
    0% {
        background-position: -100% -100%;
    }
    100% {
        background-position: 100% 100%;
    }
}